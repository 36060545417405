@import "./src/assets/scss/_mixins.scss";
              @import "./src/assets/scss/_variables.scss";

.youtube-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;

  &__iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
