@import "./src/assets/scss/_mixins.scss";
              @import "./src/assets/scss/_variables.scss";

.root {
  @include gridable(100%);
  grid-template-areas: 'block';
  grid-template-columns: 1fr;
  justify-items: center;
  align-content: space-between;
  box-sizing: border-box;
  height: 100%;
  font-family: 'Blender Pro', Arial, Helvetica, sans-serif;
  font-weight: 400;
  line-height: 1.21;
}
