@mixin flexible($width) {
  display: flex;
  width: $width;
}

@mixin gridable($width) {
  display: grid;
  width: $width;
}

@mixin button() {
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
  transition: 0.5s;
}

@mixin unmarkedList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

@mixin defaultLink {
  cursor: pointer;
  text-decoration: none;
  transition: 0.2s ease;
  position: relative;
}

@mixin titleH1 {
  text-transform: uppercase;
  font-size: 90px;
  font-weight: 900;
  margin: 0;
  text-align: center;

  @media screen and (max-width: $desktop) {
    font-size: 70px;
  }

  @media screen and (max-width: $tablet-small) {
    font-size: 50px;
    width: 100%;
  }

  @media screen and (max-width: $smartphone) {
    font-size: 40px;
    width: 100%;
  }
}

@mixin titleH2 {
  padding: 70px 20px 40px 20px;
  font-size: 70px;
  font-weight: 500;
  margin: 0;
  text-transform: uppercase;

  @media screen and (max-width: $tablet) {
    font-size: 48px;
  }

  @media screen and (max-width: $smartphone) {
    font-size: 36px;
  }
}

@mixin titleH3 {
  text-transform: uppercase;
  font-weight: 900;
  margin: 0;
  text-align: center;
  font-size: 20px;
}

@mixin text($font-size) {
  font-size: $font-size;
  line-height: 1.5;
  font-weight: 400;
  margin: 0;
}
