@import "./src/assets/scss/_mixins.scss";
              @import "./src/assets/scss/_variables.scss";

.features {
  padding: 0;

  &__list {
    @include flexible(100%);
    @include unmarkedList;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: $regular-width;
    row-gap: 74px;
    box-sizing: border-box;
    padding: 0 10px;

    @media screen and (max-width: $tablet-large) {
      max-width: 700px;
      column-gap: 40px;
    }

    @media screen and (max-width: $tablet-small) {
      justify-content: center;
      gap: 40px;
    }

    &-item {
      @include flexible(100%);
      gap: 25px;
      flex-direction: column;
      max-width: 296px;
      text-align: center;
      align-items: center;
    }
  }

  &__icon {
    width: 80px;
    height: 80px;
  }

  &__subtitle {
    @include titleH3;
    color: $color-text-dark;
  }

  &__text {
    @include text(20px);
    color: $color-text-light;
  }
}
