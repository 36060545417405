$max-width: 1900px;
$regular-width: 1170px;
$header-height: 80px;
$header-height-fixed: 60px;
$common-padding: 10px;

$color-light: #ffffff;
$color-dark: #000000;
$color-background: #34353b;
$color-decorate: #f22f20;
$color-text-dark: #2d3033;
$color-text-light: #6c7279;

$desktop: 1024px;
$tablet-large: 900px;
$tablet: 768px;
$tablet-small: 640px;
$smartphone: 480px;

$mouse-device: '(hover: hover) and (pointer: fine)';
