@import "./src/assets/scss/_mixins.scss";
              @import "./src/assets/scss/_variables.scss";

.carousel {
  @include gridable(100%);
  height: 100%;
  min-height: 650px;
  overflow: hidden;
  position: relative;
  @media screen and (max-width: $tablet) {
    min-height: 500px;
  }

  &__list {
    @include flexible(100%);
    @include unmarkedList();
    justify-self: center;
    transform: translateX(0);
    transition: all ease-in-out 0.5s;
    max-width: 1200px;

    &-item {
      @include flexible(100%);
      justify-content: center;
      flex-shrink: 0;
      position: relative;
      max-width: 1200px;
    }
  }

  &__dots {
    @include flexible(max-content);
    @include unmarkedList();
    padding: 15px;
    gap: 10px;
    position: absolute;
    justify-self: center;
    align-self: start;
    z-index: 10;

    &-item {
      cursor: pointer;
      @include unmarkedList();
      height: 5px;
      width: 40px;
      border: 1px solid $color-decorate;
      border-radius: 1px;
      transform: skewX(-38deg);
      background-color: rgba($color-decorate, 0.2);
      transition: all ease-in-out 0.5s;

      &_selected {
        background-color: rgba($color-decorate, 0.8);
      }
    }
  }

  &__image {
    height: auto;
    max-height: 700px;
    object-fit: cover;
    object-position: top;
    width: 100%;
  }

  &__info {
    @include gridable(100%);
    position: absolute;
    bottom: 30px;
    padding: 0 10px;
    box-sizing: border-box;
    gap: 10px;
    max-width: 500px;

    @media screen and (max-width: $tablet) {
      max-width: 300px;
    }
    @media screen and (max-width: $smartphone) {
      max-width: 250px;
    }
  }
  &__title {
    @include gridable(100%);
    font-size: 40px;
    margin: 0;
    color: $color-light;
    z-index: 10;
    position: relative;
    text-align: center;
    box-sizing: border-box;

    @media screen and (max-width: $tablet) {
      font-size: 24px;
    }

    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background-color: $color-decorate;
      transform: skewX(-8deg);
      z-index: -1;
    }
  }

  &__text {
    @include gridable(110%);
    font-size: 16px;
    width: 100%;
    color: $color-light;
    padding: 15px;
    position: relative;
    z-index: 10;
    margin: 0;
    box-sizing: border-box;

    @media screen and (max-width: $tablet) {
      font-size: 12px;
    }

    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background-color: rgba($color-dark, 0.8);
      transform: skewX(8deg);
      z-index: -1;
    }
  }

  &__button {
    @include button;
    @include flexible(40px);
    margin: 15px;
    border-radius: 5px;
    background: transparent;
    align-self: center;
    z-index: 10;
    height: 40px;
    border-top: 2px solid $color-decorate;
    border-bottom: 2px solid $color-decorate;
    position: absolute;
    transform: translateX(0);

    &_left {
      justify-self: start;

      &:hover {
        transform: translateX(-5px);
      }
    }

    &_right {
      justify-self: end;
      &:hover {
        transform: translateX(5px);
      }
    }
  }

  &__icon-button {
    width: 40px;
    height: 40px;
    fill: $color-decorate;
  }
}
