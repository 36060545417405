@import "./src/assets/scss/_mixins.scss";
              @import "./src/assets/scss/_variables.scss";

.about {
  position: relative;
  align-items: center;
  height: 100vh;
  background-image: url('../assets/images/background.png');
  background-repeat: no-repeat;
  background-size: cover;

  &::before {
    content: '';
    position: absolute;
    z-index: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    background-color: rgba($color-background, 0.7);
  }

  &__container {
    @include gridable(100%);
    z-index: 5;
    justify-items: center;
    box-sizing: border-box;
    max-width: calc(930px + $common-padding * 2);
    padding: $header-height $common-padding 0 $common-padding;
    color: $color-light;
  }

  &__title {
    @include titleH1;
    padding-bottom: 30px;
    line-height: 1.09;
  }

  &__text {
    @include text(22px);
    padding-bottom: 70px;
    text-align: center;
  }

  &__button {
    @include flexible(max-content);
    @include button();
    height: 58px;
    padding: 12px 36px;
    font-size: 27px;
    line-height: 1.61;
    color: $color-light;
    text-transform: uppercase;
    background-color: $color-dark;
    border: 3px solid $color-decorate;
    border-radius: 2px;

    &:hover {
      @media #{$mouse-device} {
        background-color: $color-decorate;
      }
    }
  }
}
