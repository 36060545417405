@import "./src/assets/scss/_mixins.scss";
              @import "./src/assets/scss/_variables.scss";

.footer {
  @include flexible(100%);
  justify-content: center;
  height: 100%;
  background-color: $color-background;

  &__container {
    @include flexible(100%);
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    max-width: $regular-width;
    padding: 20px 10px;
  }

  &__text {
    @include text(16px);
    padding: 0;
    color: $color-text-light;
  }
}
