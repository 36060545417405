@import "./src/assets/scss/_mixins.scss";
              @import "./src/assets/scss/_variables.scss";

.section {
  @include gridable(100%);
  justify-items: center;
  box-sizing: border-box;

  &__title {
    @include titleH2;
  }
}
