@import "./src/assets/scss/_mixins.scss";
              @import "./src/assets/scss/_variables.scss";

.projects {
  max-width: $max-width;

  &__list {
    @include gridable(100%);
    @include unmarkedList;
    grid-auto-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(460px, 1fr));
    justify-content: center;
    gap: 1px;

    @media screen and (max-width: $smartphone) {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
  }
}
.project {
  width: 100%;
  height: 475px;
  overflow: hidden;

  &__figure {
    @include flexible(100%);
    position: relative;
    height: 100%;
    margin: 0;
    cursor: pointer;

    &:hover .project__caption {
      @media #{$mouse-device} {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &__caption {
    @include flexible(100%);
    position: absolute;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    height: 100%;
    padding: 0 15px;
    text-align: center;
    color: $color-light;
    background-color: rgba($color-background, 0.7);
    visibility: hidden;
    opacity: 0;
    transition: 0.5s ease-in-out;
    gap: 13px;

    &_active {
      visibility: visible;
      opacity: 1;
    }
  }

  &__image {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
  }

  &__title {
    @include titleH3;
  }

  &__text {
    @include text(20px);
  }
}
