@import "./src/assets/scss/_mixins.scss";
              @import "./src/assets/scss/_variables.scss";

.contacts {
  &__container {
    @include gridable(100%);
    gap: 30px 15px;
    box-sizing: border-box;
    padding: 0 10px;
    max-width: $regular-width;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: $tablet) {
      grid-template-columns: 1fr;
      padding: 0;
    }
  }

  &__list {
    @include gridable(100%);
    grid-template-columns: 1fr;
    align-content: start;
    @include unmarkedList;
    @include text(20px);
    color: $color-text-dark;
    gap: 20px 40px;

    @media screen and (max-width: $tablet) {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      padding: 0 10px;
      box-sizing: border-box;
    }

    &-item {
      @include flexible(100%);
      flex-direction: column;
      gap: 5px;

      &-name {
        color: $color-text-light;
      }
    }
  }

  &__link {
    @include defaultLink;
    color: $color-decorate;
    width: max-content;

    &:hover {
      opacity: 0.8;
    }
  }
}
